<script>
  import { SideNav } from "@sveltech/bricks";
  import { getContext } from "svelte";
  import NavLinks from "../components/NavLinks.svelte";
  const routify = getContext("routify");
  const { url } = routify;
</script>

<style>:global(.show .activator.desktop) {
  color: #fff;
}

:global(.show-mobile .activator.mobile) {
  color: #fff;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9fbGF5b3V0LnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VDREYsWUFBQTtDREdHOztBQUNEO0VDSkYsWUFBQTtDRE1HIiwiZmlsZSI6InNyYy9wYWdlcy9fbGF5b3V0LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICA6Z2xvYmFsKC5zaG93IC5hY3RpdmF0b3IuZGVza3RvcCkge1xuICAgIEBhcHBseSB0ZXh0LXdoaXRlO1xuICB9XG4gIDpnbG9iYWwoLnNob3ctbW9iaWxlIC5hY3RpdmF0b3IubW9iaWxlKSB7XG4gICAgQGFwcGx5IHRleHQtd2hpdGU7XG4gIH1cbiIsbnVsbF19 */</style>

<div>
  <SideNav
    class="shadow bg-gray-700 text-white overflow-y-auto overflow-x-hidden">
    <aside slot="aside">
      <a href="/" class="m-4 mb-0 block">
        <img src="/routify4.png" alt="" />
        <br />
      </a>
      <small class="ml-4">v1.0.1-x@next</small>
      <NavLinks />
    </aside>
    <slot />
  </SideNav>
</div>

<a class="absolute top-0 right-0" href="https://github.com/sveltech/routify">
  <img src="/github.png" class="w-16" />
</a>
