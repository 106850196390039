<script>
  export let show = false;
</script>

<style>span {
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL2JyaWNrcy9zcmMvY29tcG9uZW50cy9EZXRhaWxzLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGdCQUFnQjtDQUNqQiIsImZpbGUiOiIuLi9icmlja3Mvc3JjL2NvbXBvbmVudHMvRGV0YWlscy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuICBzcGFuIHtcclxuICAgIGN1cnNvcjogcG9pbnRlcjtcclxuICB9XHJcbiJdfQ== */</style>

<!-- show activator? -->
{#if !show}
  <span class:show on:click={() => (show = !show)}>
    <slot name="activator" />
  </span>
{:else}
  <!-- else show deactivator -->
  <span class:show on:click={() => (show = false)}>
    <slot name="deactivator" />
  </span>
{/if}
{#if show}
  <slot>
    <!-- optional fallback -->
  </slot>
{/if}
