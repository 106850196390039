<style>h4 {
  margin-top: 1rem;
}

h6 {
  font-weight: bold;
}

ul {
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: #e2e8f0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

li:not(:last-child) {
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom-width: 1px;
}

@media (min-width: 640px) {
  li {
    /* @apply text-lg; */
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9kb2NzL2ludHJvZHVjdGlvbi5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQ0RGLGlCQUFBO0NER0c7O0FBQ0Q7RUFDRSxrQkFBa0I7Q0FDbkI7O0FBQ0Q7RUNQRix1QkFBQTtFQUFBLGtCQUFBO0VBQUEsc0JBQUE7RUFBQSxpQkFBQTtFQUFBLG9CQUFBO0VBQUEsdUJBQUE7RUFBQSxtQkFBQTtFQUFBLG9CQUFBO0VBQUEsb0JBQUE7RUFBQSx1QkFBQTtDRFNHOztBQUNEO0VDVkYscUJBQUE7RUFBQSxpQkFBQTtFQUFBLG9CQUFBO0VBQUEseUJBQUE7Q0RZRzs7QUFFRDtFQUNFO0lBQ0UscUJBQXFCO0dBQ3RCO0NBQ0YiLCJmaWxlIjoic3JjL3BhZ2VzL2RvY3MvaW50cm9kdWN0aW9uLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxyXG4gIGg0IHtcclxuICAgIEBhcHBseSBtdC00O1xyXG4gIH1cclxuICBoNiB7XHJcbiAgICBmb250LXdlaWdodDogYm9sZDtcclxuICB9XHJcbiAgdWwge1xyXG4gICAgQGFwcGx5IHJvdW5kZWQgYm9yZGVyIGJvcmRlci1ncmF5LTMwMCBteS00IGJnLXdoaXRlIHB4LTggcHQtMiBwYi02O1xyXG4gIH1cclxuICBsaTpub3QoOmxhc3QtY2hpbGQpIHtcclxuICAgIEBhcHBseSBwYi00IG15LTQgYm9yZGVyLWI7XHJcbiAgfVxyXG5cclxuICBAc2NyZWVuIHNtIHtcclxuICAgIGxpIHtcclxuICAgICAgLyogQGFwcGx5IHRleHQtbGc7ICovXHJcbiAgICB9XHJcbiAgfVxyXG4iLG51bGxdfQ== */</style>

<svelte:head>
  <title>Introduction - Routify</title>
</svelte:head>

<div class="max-w-3xl m-auto">
  <h1>Routify</h1>

  <h2 class="text-2xl">Routes for Svelte, automated by your filestructure.</h2>
  <div class="pt-12">
    <p>Cool, so what are the benefits?</p>
    <ul>
      <li>
        <h5>Hit the ground running</h5>
        No need to configure a single route.
      </li>
      <li>
        <h5>SSOT and KISS</h5>
        If
        <span class="code">/recipes/cupcakes</span>
        doesn't exist in your browser, it doesn't exist in your project. That's
        easy debugging.
      </li>
      <li>
        <h5>Scoped everything</h5>
        Layouts, helpers, props, fallbacks, you name it. It's all scoped and
        context-aware.
      </li>
      <li>
        <h5>Resets</h5>
        Well, sometimes you don't want everything to be scoped. Maybe you don't
        want /restaurants/burger-queen to inherit any layout.
      </li>
      <li>
        <h5>And so on</h5>
        PWA ready, Code-splitting, Realtime route generation.
      </li>
    </ul>
    <p>
      <strong>Want to have a look at the starter template?</strong><br />
      <a class="underline" href="https://routify-starter.now.sh">https://routify-starter.now.sh</a>
    </p>
  </div>
</div>
