<script>
  import { getContext } from "svelte";
  import { TABS } from "./Tabs.svelte";
  export let preserve = false

  const panel = {};
  const { registerPanel, selectedPanel } = getContext(TABS);

  registerPanel(panel);

  $: selected = $selectedPanel === panel;

  async function setHeight(e) {
    tabWindowHeight = e.clientHeight;
  }
</script>

<style>.panel {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL2JyaWNrcy9zcmMvY29tcG9uZW50cy9UYWJzUGFnZS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxPQUFPO0VBQ1AsUUFBUTtFQUNSLFNBQVM7RUFDVCxVQUFVO0VBQ1YsaUJBQWlCO0NBQ2xCIiwiZmlsZSI6Ii4uL2JyaWNrcy9zcmMvY29tcG9uZW50cy9UYWJzUGFnZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuICAucGFuZWwge1xyXG4gICAgdG9wOiAwO1xyXG4gICAgbGVmdDogMDtcclxuICAgIHJpZ2h0OiAwO1xyXG4gICAgYm90dG9tOiAwO1xyXG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcclxuICB9XHJcbiJdfQ== */</style>

<div class="panel" style="position: {selected ? 'relative' : 'absolute'}; {selected ? '' : 'z-index:-1'}">
  {#if selected || preserve}
    <slot />
  {/if}
</div>
