<script>
  import { onDestroy, tick } from "svelte";
  export let show = false,
    closeOnClick = false,
    openOnHover = false,
    xOffset = "0",
    yOffset = "100%",
    xAlign = "left",
    yAlign = "top",
    style = "";

  document.body.addEventListener("click", handleBgClick, false);

  onDestroy(() => {
    document.body.removeEventListener("click", handleBgClick, false);
  });

  function handleBgClick(event) {
    show = false;
  }

  function handleMenuClick(e) {
    show = !closeOnClick;
  }

  async function handleClick() {
    setTimeout(() => (show = !show));
  }
</script>

<style>.activator {
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL2JyaWNrcy9zcmMvY29tcG9uZW50cy9NZW51LnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFjRTtFQUNFLGdCQUFnQjtDQUNqQiIsImZpbGUiOiIuLi9icmlja3Mvc3JjL2NvbXBvbmVudHMvTWVudS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcclxuICAuYWN0aXZhdG9yLXJlZmVyZW5jZSB7XHJcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgICB0b3A6IDA7XHJcbiAgICBsZWZ0OiAwO1xyXG4gICAgcmlnaHQ6IDA7XHJcbiAgICBib3R0b206IDA7XHJcbiAgfVxyXG4gIC5tZW51IHtcclxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuICAgIG1pbi13aWR0aDogMTAwJTtcclxuICAgIHotaW5kZXg6IDEwMDtcclxuICB9XHJcblxyXG4gIC5hY3RpdmF0b3Ige1xyXG4gICAgY3Vyc29yOiBwb2ludGVyO1xyXG4gIH1cclxuIl19 */</style>

<div
  style="position:relative; display: inline-block; {style}
  "
  class:show
  class={$$props.class || ''}
  on:mouseenter={() => {
    openOnHover && (show = true);
  }}
  on:mouseleave={() => {
    openOnHover && (show = false);
  }}>
  <div class="activator" on:click={handleClick}>
    <slot name="activator">&#9776;</slot>
  </div>

  {#if show}
    <div class="activator-reference">
      <div
        class="activator-reference"
        style="transform: translate({xOffset}, {yOffset}); z-index:1">
        <div
          class="menu"
          style="{xAlign}: 0; {yAlign}:0;"
          on:click|stopPropagation={handleMenuClick}>
          <slot />
        </div>
      </div>
    </div>
  {/if}
</div>
